<template>
  <v-container fluid tag="section" class="pt-0">
    <fondo-custom></fondo-custom>

    <v-row
      justify="start"
      justify-sm="center"
      class="sticky background-gradient top-title z-index-7"
    >
      <page-top-title>
        <template v-slot:toptitle-icon>mdi-book-open</template>
        <template v-slot:toptitle-text>
          {{ $t("mi_cuenta") }}
        </template>
      </page-top-title>
    </v-row>

    <v-container tag="section">
      <v-row justify="center">
        <v-col cols="12" md="10">
          <!-- FILTROS -->
          <v-row class="sticky filters z-index-99">
            <v-container class="" fluid>
              <v-card class="pa-0" rounded="lg">
                <v-card-title
                  style="color: white"
                  class="solid-heading rounded-lg mb-n2"
                >
                <v-container>
                  <!-- <p class="white--text">{{ $t("muestras_subtitle") }}</p> -->
                  <h3 class="card-title text-h3 font-weight-light white--text">
                    <v-icon color="accent">mdi-clipboard-account</v-icon>
                    {{ $t("muestras") }}
                  </h3>

                </v-container>
                </v-card-title>
                <v-card-text class="px-0">
                  <v-container class="pa-0">
                    <v-data-table
                      :headers="headers"
                      :items="sharedSamples"
                      :items-per-page="25"
                      :search="search"
                    >
                      <template v-slot:top>
                        <v-text-field
                          v-model="search"
                          :label="$t('buscar')"
                          prepend-inner-icon="mdi-magnify"
                          filled
                          clearable
                          dense
                          hide-details=""
                          background-color="#e0e0e0"
                        ></v-text-field>
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-btn v-if="item.sample != sampleNumber" @click="loadSampleData(item.id)" color="primary" small outlined elevation="2">
                          <span class="mt-1 mr-2">{{$t("cargar_datos")}}</span><v-icon small class="" color=""> mdi-upload</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-container>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Api from "@/services/Strapi.js";

import PageTopTitle from "@/components/mylogy/PageTopTitle.vue";
import FondoCustom from "@/components/mylogy/FondoCustom.vue";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      sharedSamples: [],
      search: "",
      headers: [
        {
          text: "Nombre",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "Muestra",
          align: "start",
          sortable: true,
          value: "sample",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  components: {
    PageTopTitle,
    FondoCustom
  },
  computed: {
    ...mapGetters("gxSample", ["sampleNumber"]),
  },
  methods: {
    ...mapActions("gxSample", ["getSampleData"]),
    async loadSampleData(sampleId) {
      await this.getSampleData(sampleId);
    },
    async getSharedSamples() {
      const resp = await Api.get("/shared-samples-data");
      this.sharedSamples = resp.data;
    },
  },
  async mounted() {
    await this.getSharedSamples();
  },
};
</script>

<style lang="scss" scoped>

</style>